import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./richText.scss";

const RichText = ({ children }) => {
  return <div className="richTextField">
    {ReactHtmlParser(children)}
  </div>
};

export default RichText;
