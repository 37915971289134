import { linkTo } from "querypieces";

export default `
  resources {
    ...on resources_item_BlockType {
      id
      heading
      description
      ${linkTo}
    }
  }
`;
