import React from "react";
import YouTube from "react-youtube";
import "./youtube-embed.scss";
const aspectRatio = 56.25;

const VideoEmbed = ({ youtubeLink }) => {
  const videoId = getYouTubeId(youtubeLink);
  return (
    <div className="VideoEmbed">
      <div
        className="VideoEmbed-wrapper"
        style={{ paddingBottom: `${aspectRatio}%` }}
      >
        <YouTube videoId={videoId} opts={{ playerVars: { autoplay: 1 } }} />
      </div>
    </div>
  );
};

export default VideoEmbed;

function getYouTubeId(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}
