import { gql } from "apollo-boost";
import { blog, idtype, link } from "querypieces";

export const blogBuilder = `
blogBuilder {
  ... on blogBuilder_richText_BlockType {
    ${idtype}
    richText
  }
  ... on blogBuilder_blogImage_BlockType {
    ${idtype}
    blogImage {
      url
      title
      altText
    }
    altText
    caption
  }
  ... on blogBuilder_primaryCta_BlockType {
    ${idtype}
    primaryCta {
      ${link}
    }
  }
}
`

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on blog_default_Entry {
        ${blog}
        ${blogBuilder}
      }
    }
    seomatic (uri: $uri) {
      metaTitleContainer
      metaTagContainer
    }
  }
`;
