
import { gql } from "apollo-boost";
import { relatedArticleCard } from "querypieces";

export default gql`
{
  entries(section: "Blog") {
    ${relatedArticleCard}
  }
}
`;
