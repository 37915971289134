import { idtype } from "querypieces";
import resources from "components/resources/query";
import faq from "components/faq/query";

export default `
  ...on pageBuilder_resourcesFaq_BlockType {
    ${idtype}
    ${resources}
    ${faq}
  }
`;
