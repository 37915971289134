import { idtype, linkTo, image } from "querypieces";

export default `
...on pageBuilder_detailedNavigation_BlockType {
  ${idtype}
  detailedNavigation {
    ...on detailedNavigation_item_BlockType {
      id
      heading
      text
      ${linkTo}
      ${image}
      theme
    }
  }
  layout: detailedNavigationLayout
}
`;
