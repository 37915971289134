import { idtype, image } from "querypieces";
import youtubeModal from "blocks/youtube-modal/query";

export default `
...on pageBuilder_landingHeader_BlockType {
  ${idtype}
  superheading
  heading
  plainText
  ${image}
  backgroundVideo {
    url
  }
  children {
    ${youtubeModal}
  }
}
`;
