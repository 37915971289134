import { useQuery } from "@apollo/react-hooks";
import { idtype } from "querypieces"
import pageBuilder from "blocks/query";
import { blogBuilder } from "components/blog-post/query"
import { gql } from "apollo-boost";

const query = gql`
query($param:String, $offset: Int, $limit: Int) {
  entries(section: ["pages","blog"], search: $param, orderBy: "score",offset: $offset, limit: $limit) {
    ${idtype}
    title
    url

    ...on pages_page_Entry {
      ${pageBuilder}
    }

    ...on blog_default_Entry {
      ${blogBuilder}
    }
  }

  entryCount(section: ["pages","blog"], search: $param)
}
`

const useSearch = (param) => {
  return useQuery(query, {
    variables: {
      param,
    },
    notifyOnNetworkStatusChange: true,
  });
};

export default useSearch;
