import { useEffect } from "react";
import "./body-scripts.scss";
import query from "./query";
import { useQuery } from "@apollo/react-hooks";

const BodyScripts = () => {
  const { data } = useQuery(query);

  useEffect(() => {
    if (data) {
      data.scripts.bodyScripts.forEach((script) => {
        const div = document.createElement("div");
        div.innerHTML = script.code;
        // eslint-disable-next-line
        eval(div.querySelector("script").innerText);
      });
    }
  }, [data]);
  return null;
};

export default BodyScripts;
