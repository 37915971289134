import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import "./app.scss";
import Nav from "components/nav";
import Footer from "components/footer";
import Page from "components/page";
import Error from "components/error";
import Search from "components/search";
import AlertBar from "components/alert-bar";
import GoogleTranslate from "components/google-translate";
import Loading from "components/loading";
import BodyScripts from "components/body-scripts";
import Landing from "components/landing";
import { AppContext } from "appContext";
import BlogPost from "components/blog-post";

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || Polyfill;
}

const App = () => {
  const currentUser = localStorage.getItem("user");
  const loginContext = useContext(AppContext);

  return (
    <div className="App">
      <div className="sticky-top">
        <div className="AlertLanguage">
          <div className="AlertLanguage-inner">
            <AlertBar />
            <GoogleTranslate />
          </div>
        </div>
        <Nav />
      </div>
      {!!currentUser || loginContext.loggedIn ?
        <Routes>
          <Route path={"/loading"} element={<Loading />} />
          <Route path={"/error"} element={<Error />} />
          <Route path={"/blog/:slug"} element={<BlogPost />} />
          <Route path={"/search"} element={<Search />} />
          <Route path={"/:slug?"} element={<Page />} />
        </Routes>
        :
        <Landing
          breadcrumb={"Welcome to"}
          header={"The Fountain House Intranet"}
          text={"The internal server for all Fountain House club communications and information."}
        />
      }

      <Footer />
      <BodyScripts />
    </div>
  );
}

export default App;
