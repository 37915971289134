import React from "react";
import "./page.scss";
import { useLocation } from 'react-router-dom';
import usePage from "./usePage";
import Loading from "components/loading";
import Error from "components/error";
import Blocks from "blocks";
import SeomaticHelmet from "components/seomatic-helmet";
import Blog from "components/blog";
import NotFound from "components/not-found";

const Page = () => {
  const { loading, error, data } = usePage(
    window.location.pathname.replace("/", "")
  );

  let location = useLocation()

  if (loading) return <Loading />;
  if (error) return <Error />;

  if (!data.entry) {
    return <NotFound />;
  }

  return (
    <div>
      <SeomaticHelmet seomatic={data.seomatic} />
      <Blocks blocks={data.entry.pageBuilder} />
      {location.pathname === "/blog" ? <Blog /> : null}
    </div>
  );
};

export default Page;
