import React from "react"
import HTMLContent from "dangerously-set-html-content";

import "./embed-block.scss"

const EmbedBlock = ({
  id,
  backgroundColor,
  heading,
  embed
}) => {
  console.log(embed)
  return <section className={`EmbedBlock bg--${backgroundColor}`} id={id}>
    <div className={"EmbedBlock-wrapper"}>
      <h2 className={"EmbedBlock-heading"}>{heading}</h2>
      <div className={"EmbedBlock-copy"}>
        <HTMLContent html={embed} className={"EmbedBlock-embed"} />
      </div>
    </div>
  </section>
}

export default EmbedBlock