export const idtype = `
  id
  typeHandle
`;

export const link = `
  url
  text
  type
  element {
    id
    uri
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;

export const image = `
image {
  ...on images_Asset {
    url
    title
    altText
  }
}
`;

export const blog = `
  ${idtype}
  title
  heading
  date: superheading
  publisher: label
  authors: plainText
`

export const relatedArticleCard = `
...on blog_default_Entry {
  id
  ${blog}
  altText
  ${image}
  uri
  }
`