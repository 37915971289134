import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ children }) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        console.log(element)
        if (element) {
          element.scrollIntoView({ block: "center" });
        }
      }, 500);
    }
  }, [pathname, hash, key]);

  return children || null;
};

export default ScrollToTop;