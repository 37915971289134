import React from "react";
import "./resources.scss";
import Link from "components/link";

const Resources = ({ items }) => {
  return (
    <ul className="Resources">
      {items.map(({ id, ...rest }) => (
        <ResourcesItem key={id} {...rest} />
      ))}
    </ul>
  );
};

export default Resources;

const ResourcesItem = ({ heading, description, linkTo }) => {
  const inner = (
    <>
      <div className="ResourcesItem-heading">{heading}</div>
      <div className="ResourcesItem-description">{description}</div>
    </>
  );
  return (
    <li className="ResourcesItem">
      {linkTo.url ? <Link {...linkTo}>{inner}</Link> : inner}
    </li>
  );
};
