import React, { useState } from "react";
import "./alert-read-more.scss";
import RichText from "components/rich-text";
// import carrot from "./carrot.svg";
import { ReactComponent as Carrot } from "./carrot.svg";
import useMeasure from "react-use-measure";
import { useSpring, a } from "react-spring";

const AlertReadMore = ({
  heading,
  initialText,
  buttonLabel,
  remainingText,
}) => {
  const [open, setOpen] = useState(false);
  const [ref, measure] = useMeasure();
  const [props, set] = useSpring(() => ({ height: 0 }));
  set({
    height: open ? measure.height : 0,
  });
  return (
    <div className="AlertReadMore">
      <div className="AlertReadMore-inner">
        <div className="AlertReadMore-heading">{heading}</div>
        <div className="AlertReadMore-initialText">
          <RichText>{initialText}</RichText>
        </div>
        <button
          className={`AlertReadMore-toggle ${open ? "open" : ""}`}
          onClick={() => setOpen(!open)}
        >
          {open ? "close" : buttonLabel} <Carrot />
        </button>
        <a.div
          style={{ overflow: "hidden", ...props }}
          className="AlertReadMore-remainingText"
        >
          <div ref={ref}>
            <RichText>{remainingText}</RichText>
          </div>
        </a.div>
      </div>
    </div>
  );
};

export default AlertReadMore;
