import { gql } from "apollo-boost";
import { idtype, linkTo } from "querypieces";

export default gql`
query {
  nav: globalSet(handle: "nav") {
    ...on nav_GlobalSet {
      builder: navBuilder {
        ...on navBuilder_item_BlockType {
          ${idtype}
          ${linkTo}

          children {
            ...on navBuilder_nestedItem_BlockType {
              ${idtype}
              ${linkTo}
            }
          }
        }
      }
    }
  }
}
`;
