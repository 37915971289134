import React from "react";
import "./full-screen.scss";

const FullScreen = ({ className = "", children }) => {
  return (
    <div className={`FullScreen ${className}`}>
      <div className="FullScreen-inner">{children}</div>
    </div>
  );
};

export default FullScreen;
