import { gql } from "apollo-boost";

export default gql`
  query {
    footer: globalSet(handle: "footer") {
      ... on footer_GlobalSet {
        footer {
          ... on footer_BlockType {
            heading
            heading2
            content2
            heading3
            content3
            bottomMessage
          }
        }
      }
    }
  }
`;
