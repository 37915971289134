import { idtype } from "querypieces";

export default `
...on pageBuilder_copyBlock_BlockType {
  ${idtype}
  backgroundColor
  heading
  richText
}
`;
