import React from "react";
import Link from "components/link";
import "./button-link.scss";

const ButtonLink = ({ linkTo, className = "" }) => {
  return (
    <Link {...linkTo} className={`ButtonLink ${className}`}>
      {linkTo.text}
    </Link>
  );
};

export default ButtonLink;
