import { idtype } from "querypieces";

export default `
...on pageBuilder_tableRow_BlockType {
  ${idtype}
  richText
  heading
  plainText
}
`;
