import { idtype, linkTo, image } from "querypieces";

export default `
  ...on pageBuilder_pageHeader_BlockType {
    ${idtype}
    heading
    plainText
    ${linkTo}
    ${image}
    theme
  }
`;
