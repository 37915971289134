import React, { useEffect, useState } from "react";
import { useSpring, a } from "react-spring";
import useMeasure from "react-use-measure";
import { Link as RouterLink, NavLink } from "react-router-dom";
import "./nav.scss";
import logo from "images/FH-Intranet-Logo.svg";
import Link from "components/link";
import { useQuery } from "@apollo/react-hooks";
import query from "./query";
import useIsMobile from "hooks/useIsMobile";
import MenuIcon from "./menu-icon";

const breakpoint = 1200;

const Nav = () => {
  const { data } = useQuery(query);
  const isMobile = useIsMobile(breakpoint);
  const [open, setOpen] = useState(false);

  const openDropdown = (id, el) => {
    setOpen(id)
    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "true")
    }
  }

  const closeDropdown = (el) => {
    setOpen(false)

    if (el.hasAttribute("aria-haspopup")) {
      el.setAttribute("aria-expanded", "false")
    }
  }

  return (
    <nav className="Nav">
      <div className="Nav-inner">
        <RouterLink to="/">
          <picture>
            <source srcSet={logo} media={`(min-width: ${breakpoint}px)`} />
            <img src={logo} alt="Virtual Clubhouse" />
          </picture>
        </RouterLink>
        {isMobile ? (
          <MobileNav items={data?.nav.builder} />
        ) : (
            <ul className="Nav-links">
              {data && data.nav.builder.map(({ children, linkTo, id }) => {
                return children.length ? <li
                  key={id}
                  className={`listItem has-children ${open === id ? "active" : ""} `}
                  onMouseOver={(e) => { openDropdown(id, e.target) }}
                  onMouseOut={(e) => { closeDropdown(e.target) }}
                  onKeyUp={(e) => { openDropdown(id, e.target) }}
                  onBlur={(e) => { closeDropdown(e.target) }}
                >
                  {linkTo && linkTo.element ? <NavLink
                    to={`/${linkTo.element.uri}`}
                    className={({ isActive }) => (isActive ? "active navItem" : 'navItem')}
                    aria-haspopup="true"
                    aria-expanded="false"
                    {...linkTo}
                  >
                    {linkTo.text || linkTo.element.title}
                  </NavLink> : <button className={`navItem`}
                    aria-haspopup="true"
                    aria-expanded="false">{linkTo.text}</button>
                  }
                  <div className={`dropdown ${open && open === id ? "open" : "closed"} `}>
                    <ul className={`dropdown-wrapper ${children.length > 4 ? "wider" : ""} `} id={id}>
                      {children.map(link => <li key={link.id} className={`dropdown-item`}>
                        {linkTo ? <Link navLink={true} className={`dropdown-navItem`} {...link.linkTo} /> : ""}
                      </li>)}
                    </ul>
                  </div>
                </li> :
                  <li key={id} className={`listItem`}>
                    {linkTo.type === "entry"
                      ? <NavLink className={({ isActive }) => (isActive ? "active" : '')} to={`/${linkTo.element.uri}`}>
                        {linkTo.text || linkTo.element.title}
                      </NavLink>
                      : <Link {...linkTo} />}
                  </li>
              })}
            </ul>
          )}
      </div>
    </nav>
  );
};

export default Nav;

const MobileNav = ({ items }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "";
    return () => {
      document.body.style.overflow = "";
    };
  }, [active]);

  const closeMenu = () => setActive(false);

  return (
    <div className={`MobileNav ${active ? "active" : ""}`}>
      <div className="MobileNav-toggleph">
        <button className="MobileNavToggle" onClick={() => setActive(!active)}>
          <MenuIcon active={active} />
        </button>
      </div>
      {active && items && (
        <MobileNavItems items={items} closeMenu={closeMenu} />
      )}
    </div>
  );
};

const MobileNavItems = ({ items, closeMenu }) => {
  const [open, setOpen] = useState(false);
  const toggleDropdown = (id) => {
    setOpen(id === open ? false : id)
  }

  return (
    <ul className="MobileNav-links">
      {items.map(({ linkTo, id, children }) => {
        return children.length ? <li
          key={id}
          className={`listItem has-children ${open === id ? "active" : ""} `}
          onClick={() => { toggleDropdown(id) }}
        // onBlur={(e) => { closeDropdown(e.target) }}
        >
          <button
            className={`navItem`}
            aria-haspopup="true"
            aria-expanded="false">{linkTo.text}</button>

          <MobileDropdown
            id={id}
            open={open}
            linkTo={linkTo}
            children={children}
            closeMenu={closeMenu}
          />
        </li> :
          <li key={id} className={`listItem`}>
            {linkTo && linkTo.element ? <Link
              onClick={closeMenu}
              navLink={true}
              className={`navItem`}
              {...linkTo}
            /> : ""}
          </li>
      })}
    </ul>
  );
};

const MobileDropdown = ({ open, linkTo, id, children, closeMenu }) => {
  const [ref, measure] = useMeasure();
  const [props, set] = useSpring(() => ({ height: 0 }));
  set({
    height: open && open === id ? measure.height : 0,
    marginTop: open && open === id ? "1rem" : "0rem"
  });

  return <a.div
    className={`Mobile-dropdown`}
    style={{ ...props }}
    id={id}
  >
    <a.ul className={`Mobile-dropdown-wrapper`} ref={ref}>
      <li className={`Mobile-dropdown-item`}>
        {linkTo && <Link navLink={true} className={`Mobile-dropdown-navItem`} {...linkTo} onClick={closeMenu} />}
      </li>
      {children.map(link => <li key={link.id} className={`Mobile-dropdown-item`}>
        {linkTo ? <Link navLink={true} className={`Mobile-dropdown-navItem`} onClick={closeMenu} {...link.linkTo} /> : ""}
      </li>)}
    </a.ul>
  </a.div>
}
