import { idtype } from "querypieces";

export default `
...on pageBuilder_twoColumnBlock_BlockType {
  ${idtype}
  heading
  richText
  remainingText
}
`;
