import React from "react"
import { useQuery } from "@apollo/react-hooks"
import Loading from "components/loading";
import Error from "components/error";
import query from "./query"
import RelatedArticleCard from "components/related-article-card";
import "./blog.scss"

const Blog = () => {
  const { data, loading, error } = useQuery(query);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return <ul className={"Blog-grid"}>
    {data.entries.map(card => {
      return <li key={card.id}>
        <RelatedArticleCard link={{ element: card }} {...card} />
      </li>
    }
    )}
  </ul>
}

export default Blog