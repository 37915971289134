import React from "react"
import RichText from "components/rich-text";
import "./table-row.scss"

const TableRow = ({
  id,
  richText,
  heading,
  plainText,
  cols
}) => {
  return <div className={`TableRow cols-${cols}`} id={id}>
    <div className={"TableRow-content"}>
      {richText && <RichText>{richText}</RichText>}
    </div>
    <p className={"TableRow-label"}>{heading}</p>
    <p className={"TableRow-copy"}>{plainText}</p>
  </div>
}

export default TableRow