import React from "react";
import "./error.scss";
import FullScreen from "components/full-screen";

const Error = () => {
  return (
    <FullScreen className="Error">
      Sorry! We're experiencing technical difficulties. Try to{" "}
      <button onClick={() => window.location.reload()}>refresh the page</button>{" "}
      or check back later.
    </FullScreen>
  );
};

export default Error;
