import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga";

const Link = ({ url, text, element, children, navLink, __typename, ...rest }) => {
  return element && element.uri ? (
    <RouterLink
      to={`/${element.uri === "__home__" ? "" : element.uri}`}
      {...rest}
    >
      {children || text}
    </RouterLink>
  ) : (
      <ReactGA.OutboundLink
        to={url}
        target="_blank"
        rel="noopener noreferrer"
        eventLabel={url || "null"}
        {...rest}
      >
        {children || text}
      </ReactGA.OutboundLink>
    );
};

export default Link;
