import { useState, useEffect, useCallback } from "react";
// const pageBlocks = [
//   "landingHeader",
//   "detailedNavigation",
//   "alertReadMore",
//   "pageHeader",
//   "overviewPlatform",
//   "youtubeModal",
//   "resourcesFaq",
//   "tableBlock",
//   "tableRow",
//   "twoColumnBlock",
//   "copyBlock",
// ]

// const renderSwitch = (searchTerm, block) => {
//   if (block.heading && block.heading.toLowerCase().includes(searchTerm)) {
//     return block.heading
//   }
//   if (block.plainText && block.plainText.toLowerCase().includes(searchTerm)) {
//     return block.plainText
//   }
//   if (block.remainingText && block.remainingText.toLowerCase().includes(searchTerm)) {
//     return block.remainingText
//   }
//   if (block.richText && block.richText.toLowerCase().includes(searchTerm)) {
//     return block.richText
//   }

//   if (block.children) {
//     return block.children.map((child) => {
//       if (child.heading && child.heading.toLowerCase().includes(searchTerm)) {
//         return child.heading
//       }
//       if (child.richText && child.richText.toLowerCase().includes(searchTerm)) {
//         return child.richText
//       }
//       if (child.plainText && child.plainText.toLowerCase().includes(searchTerm)) {
//         return child.plainText
//       }
//     })
//   }
// }

const useSearchExcerpt = (searchTerm, entry) => {
  const [snippet, setSnippet] = useState({})

  const updateSnippet = useCallback((params) => {
    setSnippet(params)
    return snippet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (entry.typeHandle === "page") {
      return entry.pageBuilder.forEach((block) => {
        if (block.children) {
          return block.children.forEach((child) => {
            if (child.heading && child.heading.toLowerCase().includes(searchTerm)) {
              updateSnippet({ content: child.heading, id: child.id })
              return snippet
            } else if (child.richText && child.richText.toLowerCase().includes(searchTerm)) {
              if (child.typeHandle === "tableRow") {
                updateSnippet({ content: child, id: child.id })
              } else {
                updateSnippet({ content: child.richText, id: child.id })
              }
              return snippet
            } else if (child.plainText && child.plainText.toLowerCase().includes(searchTerm)) {
              updateSnippet({ content: child.plainText, id: child.id })
              return snippet
              // } else {
              //   console.log(snippet, "help?")
              //   return snippet
            }

            return snippet
          })
        }

        if (block.heading && block.heading.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.heading, id: block.id })
          return snippet
        } else if (block.plainText && block.plainText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.plainText, id: block.id })
          return snippet
        } else if (block.remainingText && block.remainingText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.remainingText, id: block.id })
          return snippet
        } else if (block.richText && block.richText.toLowerCase().includes(searchTerm)) {
          updateSnippet({ content: block.richText, id: block.id })
          return snippet
          // } else {
          //   return snippet
        }

        return snippet
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry, searchTerm])

  return snippet
}

export default useSearchExcerpt;

