import React from "react"
import RichText from "components/rich-text";
import "./copy-block.scss"

const CopyBlock = ({
  id,
  backgroundColor,
  heading,
  richText,
}) => {
  return <section className={`CopyBlock bg--${backgroundColor}`} id={id}>
    <div className={"CopyBlock-wrapper"}>
      <h2 className={"CopyBlock-heading"}>{heading}</h2>
      <div className={"CopyBlock-copy"}>
        <RichText>{richText}</RichText>
      </div>
    </div>
  </section>
}

export default CopyBlock