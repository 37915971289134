import { idtype } from "querypieces";

export default `
  ...on pageBuilder_alertReadMore_BlockType {
    ${idtype}
    heading
    initialText
    buttonLabel
    remainingText
  }
`;
