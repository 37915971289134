import { gql } from "apollo-boost";

export default gql`
  query {
    scripts: globalSet(handle: "scripts") {
      ... on scripts_GlobalSet {
        bodyScripts {
          ... on bodyScripts_script_BlockType {
            id
            code
          }
        }
      }
    }
  }
`;
