import React, { useState } from "react";
import "./youtube-modal.scss";
import Modal from "react-modal";
import YoutubeEmbed from "components/youtube-embed";
import play from "./play.svg";
import SVG from "react-inlinesvg";

const customStyles = {
  overlay: {
    padding: "2em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  content: {
    padding: 0,
    width: "100%",
    maxWidth: `${80 * (16 / 9)}vh`,
    maxHeight: `${80 * (16 / 9)}vw`,
    margin: "auto",
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const Cta = ({ text, ...rest }) => {
  return (
    <button {...rest}>
      {text} <SVG src={play} />
    </button>
  );
};

const YoutubeModal = ({ buttonLabel, youtubeLink }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  return (
    <div>
      <Cta
        className="YoutubeModal-button theme--yellow"
        text={buttonLabel}
        onClick={() => setOpen(!open)}
      />
      <Modal
        className="VideoModal"
        isOpen={open}
        //onAfterOpen={afterOpenModal}
        onRequestClose={close}
        style={customStyles}
        contentLabel="Video"
      >
        <button className="VideoModal-close" onClick={close}>
          Close
        </button>

        <YoutubeEmbed youtubeLink={youtubeLink} />
      </Modal>
    </div>
  );
};

export default YoutubeModal;
