import React from "react"
import { useParams } from 'react-router-dom';
import "./blog-post.scss"
import { Link } from "react-router-dom";

import SeomaticHelmet from "components/seomatic-helmet";
import Loading from "components/loading";
import Error from "components/error";
import Image from "components/image";
import RichText from "components/rich-text";
import ButtonLink from "components/button-link";
import { ReactComponent as Arrow } from "images/arrow.svg";
import usePost from "./useBlogPost";

const BlogPost = () => {
  let { slug } = useParams();
  const { loading, error, data } = usePost(
    `blog/${slug}`
  );
  if (loading) return <Loading />;
  if (error || !data) return <Error />;

  const { heading, date, publisher, authors, blogBuilder } = data.entry

  return (
    <section>
      <SeomaticHelmet seomatic={data.seomatic} />
      <div className={"Blog-contentWrapper"}>
        <Link
          to="/blog"
          className={"Blog-typeHandle"}
          title="to blog index page"
        >
          <Arrow />
          <span className={"Blog-typeName"}>Blog</span>
        </Link>
        <h1 className={"Blog-articleHeader"}>{heading}</h1>
        <div className={"Blog-details"}>
          {date && <p className={"Blog-date"}>{date}</p>}
          {publisher && <p className={"Blog-publisher"}>{publisher}</p>}
        </div>
        {authors ?
          <div className={"Blog-authors"}>
            <p> {authors} </p>
          </div>
          : null}
        <div className={"Blog-articleContentWrapper"}>
          {blogBuilder.map((elem) => {
            if (elem.typeHandle === 'richText') {
              return (
                <div key={elem.id} className={"Blog-articleContent"}>
                  <RichText>{elem.richText}</RichText>
                </div>
              );

            } else if (elem.typeHandle === "primaryCta") {
              console.log(elem.primaryCta)
              return (
                (elem.primaryCta.url || elem.primaryCta.element) ?
                  <ButtonLink
                    className={"Blog-blogBtn"}
                    linkTo={elem.primaryCta}
                  >
                  </ButtonLink> : null
              );
            } else {
              return (
                <React.Fragment key={elem.id}>
                  {elem.blogImage.length > 0 ?
                    <figure className={"Blog-image"}>
                      <Image className={"Blog-blogImage"} alt={elem.altText} title={elem.blogImage[0].title} {...elem.blogImage[0]} />
                    </figure>
                    : null
                  }
                  {elem.caption ? <p className={"Blog-caption"}> {elem.caption} </p> : ''}
                </React.Fragment>
              );
            }
          })
          }
        </div>
      </div>
    </section>
  );
}

export default BlogPost