import { gql } from "apollo-boost";
import { idtype } from "querypieces";

export default gql`
query($heading: [QueryArgument], $username: [String]) {
  entries(section: "siteUser", heading: $heading) {
    ... on siteUser_default_Entry {
      ${idtype}
      username: heading
      email: superheading
    }
  }
  users(username: $username) {
    id
    username
    email
  }
  globalSet(handle: "sitePassword") {
    ...on sitePassword_GlobalSet {
      password: heading
    }
  }
}
`;
