import React, { useEffect, useState } from "react";
import "./google-translate.scss";
import Select from "react-select";
import SVG from "react-inlinesvg";
import x from "./x.svg";

const colors = {
  darkGreen: "#002B00",
  teal: "#007B4D",
  lightGreen: "#ECF2C9",
  white: "#ffffff",
};
const dropdownTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: colors.darkGreen,
      primary25: colors.lightGreen,
      // primary: "black",
    },
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
    },
  };
};

const dropdownStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      border: 0,
      color: colors.teal,
      backgroundColor: state.isFocused ? colors.darkGreen : colors.teal,
      boxShadow: 0,
      "&:hover": {
        backgroundColor: colors.darkGreen,
      },
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: colors.white,
    "&:hover": {
      color: colors.white,
    },
  }),
  singleValue: (provided, state) => {
    return {
      ...provided,
      color: colors.white,
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      marginTop: 0,
      color: colors.darkGreen,
      boxShadow: `0 8px 11px hsla(0,0%,0%,0.1)`,
      zIndex: 2,
    };
  },
  placeholder: (provided, state) => {
    return {
      ...provided,
      color: state.isFocused ? "black" : colors.white,
    };
  },
};

const GoogleTranslate = () => {
  const [language, setLanguage] = useState("en");
  //const [gt, setGt] = useState();
  useEffect(() => {
    let addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      `//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit`
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const resetLang = () => {
    let iframe = document.getElementById(":1.container");
    let close = iframe
      ? iframe.contentDocument.getElementById(":1.close")
      : null;

    if (close) {
      if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", false, true);
        close.dispatchEvent(evt);
      } else {
        close.fireEvent("onclick");
      }
    }

    setLanguage("en");
  };

  const googleTranslateElementInit = () => {
    const thisGt = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout:
          window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
        //autoDisplay: false,
        //multilanguagePage: true,
        //gaTrack: true,
        //gaId: "UA-7237718-1",
        //key: process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY,
      },
      "google_translate_element"
    );
    const int = setInterval(() => {
      if (thisGt.la) {
        //setGt(thisGt);
        //setLanguage(thisGt.a.cd);
        clearInterval(int);
      }
    }, 100);
  };

  useEffect(() => {
    let googleTag = document.getElementById("google_translate_element");
    let select = googleTag ? googleTag.querySelector("select") : null;

    if (language === "en") {
      resetLang();
    } else {
      // document.documentElement.lang = `${language}-x-mtfrom-en`;
      // let linkRel = document.createElement("link");
      // linkRel.rel = "alternate machine-translated-from";
      // linkRel.hrefLang = "en";
      // linkRel.href = process.env.PUBLIC_SITE_URL;
      // document.head.appendChild(linkRel);

      if (select) {
        select.value = language;

        if ("createEvent" in document) {
          let evt = document.createEvent("HTMLEvents");
          evt.initEvent("change", false, true);
          select.dispatchEvent(evt);
        } else {
          select.fireEvent("onchange");
        }
      }
    }
  }, [language]);

  // const options = gt
  //   ? Object.keys(gt.la)
  //       .filter((d) => d !== "auto")
  //       .map((key) => ({ value: key, label: gt.la[key] }))
  //   : [];

  const options = [
    {
      value: "ar",
      label: "Arabic"
    },
    {
      value: "zh-CN",
      label: "Chinese"
    },
    {
      value: "en",
      label: "English"
    },
    {
      value: "fr",
      label: "French"
    },
    {
      value: "de",
      label: "German"
    },
    {
      value: "pt",
      label: "Portuguese"
    },
    {
      value: "es",
      label: "Spanish"
    },
  ]

  return (
    <>
      <div className="GoogleTranslate">
        <Select
          //defaultMenuIsOpen={true}
          onChange={({ value }) => {
            setLanguage(value);
          }}
          isSearchable={false}
          options={options}
          styles={dropdownStyles}
          theme={dropdownTheme}
          defaultValue={{
            value: "en",
            label: "English",
          }}
          value={options.find(({ value }) => value === language)}
          placeholder={"Select Language"}
        />
        {language && language !== "en" && (
          <button className="GoogleTranslate-reset" onClick={() => resetLang()}>
            <SVG src={x} />
          </button>
        )}
      </div>
      <div id="google_translate_element"></div>
    </>
  );
};

export default GoogleTranslate;
