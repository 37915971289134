import landingHeader from "./landing-header/query";
import detailedNavigation from "./detailed-navigation/query";
import alertReadMore from "./alert-read-more/query";
import pageHeader from "./page-header/query";
import overviewPlatform from "./overview-platform/query";
import youtubeModal from "./youtube-modal/query";
import resourcesFaq from "./resources-faq/query";
import tableBlock from "./table-block/query";
import tableRow from "./table-row/query";
import twoColumnBlock from "./two-column-block/query";
import copyBlock from "./copy-block/query";
import embedBlock from "./embed-block/query";

export default `
  pageBuilder {
    ${landingHeader}
    ${detailedNavigation}
    ${alertReadMore}
    ${pageHeader}
    ${overviewPlatform}
    ${youtubeModal}
    ${resourcesFaq}
    ${tableBlock}
    ${tableRow}
    ${twoColumnBlock}
    ${copyBlock}
    ${embedBlock}
  }
`;
