import React from "react";
import "./resources-faq.scss";
import Resources from "components/resources";
import Faq from "components/faq";

const ResourcesFaq = ({ resources, faq }) => {
  return (
    <div className="ResourcesFaq">
      <div className="ResourcesFaq-heading">Resources</div>
      <div className="ResourcesFaq-inner">
        <Resources items={resources} />
        <Faq items={faq} />
      </div>
    </div>
  );
};

export default ResourcesFaq;
