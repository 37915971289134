import React from "react";
// import Link from "components/link";
import "./page-header.scss";

const PageHeader = ({
  heading,
  plainText,
  color = "dark-green"
  // linkTo,
  // image,
  // theme
}) => {
  return (
    <header className={`PageHeader`}>
      <div className="PageHeader-inner">
        <h1
          className="PageHeader-heading"
          style={{ color: `var(--${color})` }}
        >{heading}</h1>
        {plainText && <h2 className="PageHeader-text">{plainText}</h2>}
      </div>
    </header>
  );
};

export default PageHeader;
