import { idtype } from "querypieces";
import tableRow from "blocks/table-row/query"

export default `
...on pageBuilder_tableBlock_BlockType {
  ${idtype}
  heading
  subheading: superheading
  colHead1: buttonLabel
  colHead2: label
  colHead3: plainText
  backgroundColor
  children {
    ${tableRow}
  }
}
`;
