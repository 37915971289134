import React from "react";
import "./alert-bar.scss";
import query from "./query";
import { useQuery } from "@apollo/react-hooks";
import Link from "components/link";

const AlertBar = () => {
  const { loading, error, data } = useQuery(query);

  if (loading || error) {
    return null;
  }

  const { plainText, linkTo } = data.alertBar;
  return (
    <div className="AlertBar">
      <div className="AlertBar-text">{plainText}</div>
      <Link className="AlertBar-link" {...linkTo} />
    </div>
  );
};

export default AlertBar;
