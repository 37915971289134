import { useQuery } from "@apollo/react-hooks";
import query from "./query";

const usePage = (uri) => {
  if (uri === "") {
    uri = "__home__";
  }
  return useQuery(query, {
    variables: {
      uri,
    },
    notifyOnNetworkStatusChange: true,
  });
};

export default usePage;
