import React from "react";
import "./landing-header.scss";
// import Image from "components/image";
import Blocks from "blocks";

const LandingHeader = ({
  superheading,
  heading,
  plainText,
  children,
  // image,
  // backgroundVideo,
}) => {
  return (
    <div className="LandingHeader">
      <div className="LandingHeader-inner">
        <div className="LandingHeader-superheading">{superheading}</div>
        <div className="LandingHeader-heading">{heading}</div>
        <div className="LandingHeader-text">{plainText}</div>
        {children.length > 0 && (
          <div className="LandingHeader-link">
            <Blocks blocks={children} />
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingHeader;
