import { useQuery } from "@apollo/react-hooks";
import query from "./query";

const usePost = (uri) => {
  return useQuery(query, {
    variables: {
      uri: uri,
    },
    notifyOnNetworkStatusChange: true,
  });
};

export default usePost;
