import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_overviewPlatform_BlockType {
    ${idtype}
    overview {
      ...on overview_BlockType {
        heading
        plainText
      }
    }
    platform {
      ...on platform_BlockType {
        heading
        text
        ${linkTo}
      }
    }
  }
`;
