import { idtype } from "querypieces"
import { gql } from "apollo-boost";

export default gql`
query {
  entry(section: "search") {
    ... on search_search_Entry {
      ${idtype}
      heading
      plainText
    }
  }
}
`