import React from "react";
import "./detailed-navigation.scss";
import Image from "components/image";
import ButtonLink from "components/button-link";

const DetailedNavigation = ({ detailedNavigation, layout }) => {
  return (
    <ul className={`DetailedNavigation DetailedNavigation--${layout}`}>
      {detailedNavigation.map((item) => (
        <DetailedNavigationItem key={item.id} {...item} />
      ))}
    </ul>
  );
};

export default DetailedNavigation;

const DetailedNavigationItem = ({ heading, text, image, linkTo, theme }) => {
  return (
    <li className="DetailedNavigation-item">
      <div className="DetailedNavigation-item-heading">{heading}</div>
      <div className="DetailedNavigation-item-text">{text}</div>
      <div className="DetailedNavigation-item-link">
        <ButtonLink className={`theme--${theme}`} linkTo={linkTo} />
      </div>
      <div className="DetailedNavigation-item-image">
        <Image {...image[0]} />
      </div>
    </li>
  );
};
