import pageBuilder from "blocks/query";
import { gql } from "apollo-boost";

export default gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on pages_page_Entry {
        title
        ${pageBuilder}
      }
    }
    seomatic (uri: $uri) {
      metaTitleContainer
      metaTagContainer
    }
  }
`;
