import React, { useState, createContext, useEffect } from "react";

export const AppContext = createContext();
export const AppProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const login = () => {
    localStorage.setItem("user", user ? user.id : "");
  }

  useEffect(() => {
    localStorage.setItem("user", user ? user.id : "");
  }, [user])

  // const AUTH_TOKEN = '';
  // const authLink = setContext((_, { headers }) => {
  //   const token = localStorage.getItem(AUTH_TOKEN);
  //   return {
  //     headers: {
  //       ...headers,
  //       authorization: token ? `Bearer ${token}` : ''
  //     }
  //   };
  // });

  return (
    <AppContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        login
      }}
    >
      {children}
    </AppContext.Provider>
  );
};