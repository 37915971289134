import React from "react";
import "./overview-platform.scss";
import Link from "components/link";
import RichText from "components/rich-text";

const OverviewPlatform = ({ overview, platform }) => {
  return (
    <div className="OverviewPlatform">
      <Overview {...overview[0]} />
      <Platform {...platform[0]} />
    </div>
  );
};

export default OverviewPlatform;

const Overview = ({ heading, plainText }) => {
  return (
    <div className="Overview">
      <div className="Overview-heading">{heading}</div>
      <div className="Overview-text">{plainText}</div>
    </div>
  );
};

const Platform = ({ label = "Digital Platform", heading, text, linkTo }) => {
  return (
    <div className="Platform">
      <div className="Platform-label">{label}</div>

      <div className="Platform-inner">
        <div className="Platform-heading">{heading}</div>
        <div className="Platform-text">
          <RichText>{text}</RichText>
        </div>
        <Link {...linkTo} className="Platform-link" />
      </div>
    </div>
  );
};
