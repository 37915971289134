import React from "react"
import RichText from "components/rich-text";
import "./two-column-block.scss"

const TwoColumnBlock = ({
  heading,
  richText,
  remainingText,
}) => {
  return <section className={`TwoColumnBlock`}>
    <div className={"TwoColumnBlock-wrapper"}>
      <h2 className={"TwoColumnBlock-heading"}>{heading}</h2>
      <div className={"TwoColumnBlock-copy"}>
        <RichText>{richText}</RichText>
        <RichText>{remainingText}</RichText>
      </div>
    </div>
  </section>
}

export default TwoColumnBlock
