import React from "react"
import TableRow from "blocks/table-row"
import "./table-block.scss"

const TableBlock = ({
  heading,
  subheading,
  colHead1,
  colHead2,
  colHead3,
  backgroundColor,
  children
}) => {
  let rows = !!colHead3 ? "3" : "2"

  return <section className={`TableBlock bg--${backgroundColor}`}>
    <div className={"TableBlock-wrapper"}>

      {heading && <h2 className={"TableBlock-heading"}>{heading}</h2>}
      {subheading && <h3 className={"TableBlock-subheading"}>{subheading}</h3>}

      <div className={"TableBlock-table"}>
        <div className={`TableBlock-tablehead cols-${rows}`}>
          {colHead1 && <h4>{colHead1}</h4>}
          {colHead2 && <h4>{colHead2}</h4>}
          {colHead3 && <h4>{colHead3}</h4>}
        </div>


        <div className={`TableBlock-tablebody cols-${rows}`}>
          {children.map(block => <TableRow key={block.id} cols={rows} {...block} />)}
        </div>
      </div>
    </div>
  </section>
}

export default TableBlock