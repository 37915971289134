import React from "react"
import Link from "components/link";
import Image from "components/image";
import { ReactComponent as Bubble } from 'images/bubble.svg';

import "./related-article-card.scss";

const RelatedArticleCard = ({
  id,
  className = '',
  link,
  altText,
  image,
  heading,
  date,
  publisher,
  authors,
}) => {
  return (
    <div key={id} className={`${className} Card-singleCard`}>
      <Link {...link}>
        <div className={"Card-cardInner"} >
          <div className={"Card-cardTop"}>
            <p>Blog</p>
          </div>
          <div className={`${"Card-icon"}`}>
            <Bubble />
          </div>
          <div className={"Card-articleImageWrapper"}>
            {image ? <Image className={"Card-articleImg"} alt={altText} {...image[0]} /> : null}
          </div>
          <h3 className={"Card-cardTitle"}>{heading}</h3>
          <div className={"Card-filler"}></div>
          {date ? <p className={"Card-cardDate"}>{date}</p> : null}
          {publisher ? <p className={"Card-cardPublisher"}>{publisher}</p> : null}
          {authors ? <p className={"Card-cardAuthor"}>{authors}</p> : null}
        </div>
      </Link>
    </div>
  );
}


export default RelatedArticleCard