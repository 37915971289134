import React, { useState } from "react";
import "./faq.scss";
import SVG from "react-inlinesvg";
import carrot from "blocks/alert-read-more/carrot.svg";
import RichText from "components/rich-text";

const Faq = ({ items }) => {
  return (
    <div className="Faq">
      <div className="Faq-heading">Frequently Asked Questions</div>
      <ol className="Faq-list">
        {items.map(({ id, ...rest }) => (
          <FaqItem key={id} {...rest} />
        ))}
      </ol>
    </div>
  );
};

export default Faq;

const FaqItem = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  return (
    <li className="Faq-item">
      <button
        onClick={toggle}
        className={`Faq-item-question ${open ? "open" : ""}`}
      >
        <span className="Faq-item-question-text">{question}</span>
        <SVG src={carrot} />
      </button>
      {open && (
        <div className="Faq-item-answer">
          <RichText>{answer}</RichText>
        </div>
      )}
    </li>
  );
};
