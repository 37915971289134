import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useSearchParams, Link } from "react-router-dom";
import useSearch from "./useSearch";
import useSearchExcerpt from "hooks/useSearchExcept"
import PageHead from "blocks/page-header";
import TableRow from "blocks/table-row";
import query from "./query";
import "./search.scss";

const Search = () => {
  const { data } = useQuery(query);
  const [queryParameters] = useSearchParams()
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    // setQueryParams(searchTerm)
    // queryParameters.get("query", searchTerm)
  }

  useEffect(() => {
    setSearchTerm(queryParameters.get("query"))
  }, [queryParameters])


  return <div>
    {data && data.entry ?
      <PageHead heading={data.entry.heading} plainText={data.entry.plainText} color="green" />
      : ""}

    <section className={"Search bg--white"}>
      <form className={"Search-form-wrap"} onSubmit={handleSubmit}>
        <label
          className={"Search-form-label"}
          htmlFor="search">Search by keyword</label>
        <input
          className="Search-form-input"
          type="text"
          id="search"
          name="search"
          value={searchTerm || ""}
          onChange={handleChange}
        />
        <button className="Search-form-submit">Search</button>
      </form>

      <Results param={searchTerm} />
    </section>
  </div>
}

const Results = ({ param }) => {
  const { data, loading } = useSearch(param)

  if (loading) {
    return <div className="Search-results">
      <h4 className="Search-result">Loading</h4>
    </div>
  }

  if (!param) {
    return <div className="Search-results">
    </div>
  }

  return data && data.entries ? <ul className="Search-results">
    {data.entries.length ? data.entries.map(entry => {
      return <li key={entry.id}>
        <ResultItem entry={entry} param={param} />
      </li>
    }) : <div className="">
        <h4 className="Search-result">No results.</h4>
      </div>}
  </ul> : ""
}

const ResultItem = ({ entry, param }) => {
  const excerpt = useSearchExcerpt(param, entry)

  return <Link to={`${entry.url}#${excerpt.id}`} className="Search-result-link">
    <div className={"Search-result"}>{entry.title}</div>
    {excerpt && typeof excerpt.content === "string" ?
      <div className={"Search-snippet"} dangerouslySetInnerHTML={{ __html: excerpt.content }} />
      : <div className={"Search-snippet"}><TableRow {...excerpt.content} /></div>
    }
  </Link>
}

export default Search