import { gql } from "apollo-boost";
import { linkTo } from "querypieces";

export default gql`
query {
  alertBar: globalSet (handle:"alertBar") {
    ...on alertBar_GlobalSet {
      plainText
      ${linkTo}
    }
  }
}
`;
