import React from "react";
import "./footer.scss";
import query from "./query";
import { useQuery } from "@apollo/react-hooks";
import RichText from "components/rich-text";
import logo from "images/logo_white.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const { data } = useQuery(query);

  return (
    <footer className="Footer">
      <div className="Footer-top">
        <div className="Footer-heading">{data?.footer.footer[0].heading}</div>
        <FooterInfo
          heading={data?.footer.footer[0].heading2}
          content={data?.footer.footer[0].content2}
        />
        <FooterInfo
          heading={data?.footer.footer[0].heading3}
          content={data?.footer.footer[0].content3}
        />
      </div>
      <div className="Footer-bottom">
        <div className="Footer-bottom-inner">
          <Link to="https://fountainhouse.org">
            <RichText>{data?.footer.footer[0].bottomMessage}</RichText>
            <img className="Footer-bottom-inner-logo" src={logo} alt="" />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const FooterInfo = ({ heading, content }) => {
  return (
    <div className="Footer-info">
      <div className="Footer-info-heading">{heading}</div>
      <div className="Footer-info-text">
        <RichText>{content}</RichText>
      </div>
    </div>
  );
};
